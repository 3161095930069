@import "variables.scss";

body {
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  font-family: $fontStackBody;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-align: left;
  color: $colorFontBody;
  font-size: $fontSizeBaseMobile;

  @media (min-width: 768px) {
    font-size: $fontSizeBase;
  }
}

h1,
.h1 {
  font-family: $fontStackHeader;
  font-weight: normal;
  color: $colorFontBody;
  line-height: 1.2;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 $fontSizeBase 0;

  @media (min-width: 768px) {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-family: $fontStackHeader;
  font-weight: normal;
  color: $colorFontBody;
  line-height: 1.2;
  font-size: 16px;
  margin: 0 0 $fontSizeBase 0;

  @media (min-width: 768px) {
    font-size: 26px;
  }
}

p,
.p {
  font-family: $fontStackBody;
  font-weight: normal;
  font-size: $fontSizeBaseMobile;
  line-height: 1.5;
  margin: 0 0 $fontSizeBase 0;
  color: $colorFontBody;

  @media (min-width: 768px) {
    font-size: $fontSizeBase;
  }
}

a,
p a {
  font-weight: normal;
  text-decoration: none;
  color: $colorFontBody;
}

ul {
  color: $colorFontBody;

  li {
    font-size: $fontSizeBase;
    line-height: 1.5;
  }
}

// Visible to screen readers only
.sr-only {
  position: fixed;
  top: -100%;
  left: -100%;
  color: rgba(0, 0, 0, 0);
  user-select: none;
}
