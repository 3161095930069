.slots {
  .slotStatus {
    padding: 4px 8px;
    font-size: 13px;
    // font-weight: 600;
    border-radius: 15px;
    text-transform: uppercase;

    &.available {
      background-color: lightgreen;
    }
    &.reserved {
      background-color: pink;
    }
    &.notAvailable {
      background-color: orange;
    }
  }

  &--field {
    margin: 20px 0;
    display: flex;
    flex-direction: row;

    input[type="radio"] {
      margin-right: 5px;
    }
  }
  &--fieldLabel {
    width: 100px;
  }
  &--radioLabel {
    margin-right: 20px;
  }
}
