@import "reset.scss";
@import "typography.scss";

body {
  font-size: $fontSizeBase;
}

table {
  font-size: $fontSizeBase;
}
