body {
  background: #ddd;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.wrapper {
  background: white;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #222;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
}

.main {
  padding: 100px 30px 30px 30px;
}

button {
  height: 40px;
  border: 1px solid #ddd;
  background: white;
  color: black;
  font-weight: 600;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
