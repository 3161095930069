.itineraries {
  &--newItinerary {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1280px;
  }
  &--field {
    display: flex;
    flex-direction: row;
    select:first-of-type {
      margin-left: 15px;
    }
  }
  &--artistInput {
    max-width: 250px;
  }
}
