.gifts {
  &--list {
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
  }
  .giftStatus.pending {
    background-color: lightyellow;
  }
  .giftStatus.confirmed {
    background-color: lightgreen;
  }
  .giftStatus.rejected,
  .giftStatus.cancelled {
    background-color: pink;
  }

  .giftArtistStatus.assigned {
    background-color: lightgreen;
  }
  .giftArtistStatus.unassigned {
    background-color: pink;
  }
}
