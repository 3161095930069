.artists {
  button {
    margin-left: 10px;
  }
  &--field {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
  }
  &--fieldLabel {
    width: 170px;
  }
}
